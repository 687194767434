
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref, computed } from "vue";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import PaginationUtill from "@/views/util/PaginationUtill";
export default defineComponent({
  name: "assetRegistryElectricity",

  components: { TableTab },
  setup() {
    const table = ref(false);
    const colnamesElec = ref(null);
    // colnames.value.length = 0;
    let accountId = store.getters.AccountId;
    const tableData = ref([]);
    interface TableHeader {
      name: string;
      key: string;
      sortable: boolean;
    }
    const OriginalTableHeaders = ref<Array<TableHeader>>();
    const accountInfo: any = {
      accountid: accountId,
      stIndx: 1,
      enIndx: 500,
      DomainName: store.getters.DomainName.toLowerCase(),
    };
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Electricity", ["Asset Register List"]);
    });
    try {
      ApiService.post(
        "api/AssetRegisterSupplier/assetRegisterSupplier",
        accountInfo
      )
        .then(async ({ data }) => {
          colnamesElec.value = PaginationUtill.getElecColumn.map((a) => a.key);
          OriginalTableHeaders.value = PaginationUtill.getElecColumn.map(
            (a) => ({
              name: a.name,
              key: a.key,
              sortable: true,
            })
          );
          table.value = true;
          tableData.value = data.assetregelec.map((a) => ({
            siteid: a.siteid,
            electdetailsid: a.electdetailsid,
            suppliername: a.suppliername,
            siteref: a.siteref,
            billingstreet: a.billingstreet,
            billingcity: a.billingcity,
            billingcountry: a.billingcountry,
            billingpostcode: a.billingpostcode,
            msn: a.msn,
          }));
        })
        //.catch();
        .catch((a) => {
          console.log("API Error:", a);
        });
    } catch (error) {
      console.log("error:", error);
    }
    table.value = false;
    const pageheader = "Asset Register List - Electricity";
    return {
      pageheader,
      OriginalTableHeaders,
      tableData,
      colnamesElec,
      table,
    };
  },
});
