import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableTab = _resolveComponent("TableTab")!

  return (_ctx.table)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TableTab, {
          "table-data": _ctx.tableData,
          "table-header": _ctx.OriginalTableHeaders,
          "enable-items-per-page-dropdown": true,
          selectedType: _ctx.colnamesElec,
          pagetitle: _ctx.pageheader
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.colnames, (slot) => {
            return {
              name: `cell-${slot}`,
              fn: _withCtx(({ row: customer }) => [
                _createTextVNode(_toDisplayString(customer[slot]), 1)
              ])
            }
          })
        ]), 1032, ["table-data", "table-header", "selectedType", "pagetitle"])
      ]))
    : _createCommentVNode("", true)
}